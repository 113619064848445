import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import { get_course, get_courselines } from "../../../actions/action-course";
import Loader from "../../common/Loader";
import { history } from "../../../reducers";
import SidebarWrapper from "../../layout/SidebarWrapper";
import Player from "../Vod/Player";
import CommonDelete from "../../common/CommonDelete";
import { timeFormat } from "../../common/CommonFunctions";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from "../../../actions/action-snackbar";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const store = configureStore();

class MyCourseDetails extends Component {
  constructor() {
    super();
    this.state = {
      courseDetails: {},
      signedUrl: "",
      courselines: [],
      currentVideo: "",
      currentSummary: "",
      currentSequenceNumber: "",
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
    this.fetchDetails();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }
  componentWillUnmount() {}

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    console.log("id : ", id);
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const { get_course, get_courselines } = this.props;
    await get_course(activeOuid, activeOuidChain, id);
    await get_courselines(activeOuid, id);
    console.log("fetched course details: ", store.getState().course);
    this.setState({
      courseDetails: store.getState().course.courseDetails,
      courselines: store.getState().course.courselines,
    });
    if (this.state.courselines.length > 0) {
      this.state.courselines.forEach((element) => {
        console.log("element.data.sequenceNumber", element.data.sequenceNumber);
        if (element.data.sequenceNumber === "1") {
          console.log("element.data.courseVideo", element.data.courseVideo);
          element["currentSelection"] = "true";

          this.setState({
            currentVideo: element.data.courseVideo,
            currentSequenceNumber: element.data.sequenceNumber,
            currentSummary: element.data.summary,
            currentTitle: element.data.title,
            currentIdentifier: element.data.identifier,
          });
        }
      });
    }
  }

  onInputChange = (event) => {
    if (event.target.id) {
      this.setState({ ...this.state, [event.target.id]: event.target.value });
    } else {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
  };

  renderCourse() {
    const { showGlobalLoader, showGlobalLoaderCourseline } =
      store.getState().course;
    const { activeOuid, userRoles, policies, activeOuidType } =
      store.getState().permission;
    const path = window.location.pathname.split("/");
    console.log("activeOuidType in renderCourse : ", activeOuidType);

    console.log(
      "store.getState().course.courseDetails : ",
      store.getState().course.courseDetails
    );

    return (
      <div className="">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <div className="CourseGrid">
            <div className="CourseVideoWrapper">
              <div className="currentVideo">
                {showGlobalLoaderCourseline ? (
                  <Loader />
                ) : store.getState().course.courselines.length !== 0 ? (
                  <>
                    <Player urlSrc={this.state.currentVideo} />
                    <div className="CourseModuleTitle">
                      <h2>{this.state.currentTitle}</h2>
                    </div>
                    <div>Chapter: {this.state.currentSequenceNumber}</div>
                    <div>{this.state.currentSummary}</div>
                    {/* <div className="CourseModuleTitleTestBtn">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => {
                          history.push(
                            `/${activeOuid}/test/${this.state.currentIdentifier}`
                          );
                        }}
                      >
                        Take Test
                      </Button>
                    </div> */}
                    
                  </>
                ) : (
                  "No Existing Course Chapters....."
                )}
              </div>
              
            </div>

            <Paper className="CourseModuleWrapper">
              {showGlobalLoaderCourseline ? (
                <Loader />
              ) : store.getState().course.courselines.length !== 0 ? (
                <div>
                  {this.renderCourselines(
                    store.getState().course.courselines,
                    store.getState().course.courseDetails.data.identifier
                  )}
                </div>
              ) : (
                "No Existing Course Chapters....."
              )}
              <div className="CourseDetailsWrapper">
                <div className="">
                  {store.getState().course.courseDetails &&
                  store.getState().course.courseDetails.data ? (
                    <h1>{store.getState().course.courseDetails.data.title}</h1>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {store.getState().course.courseDetails &&
                  store.getState().course.courseDetails.data ? (
                    <>{store.getState().course.courseDetails.data.summary}</>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Paper>
          </div>
        )}
      </div>
    );
  }

  renderDetails(details) {
    return Object.keys(details).map(function (keyName, keyIndex) {
      return (
        <div key={keyName} className="detailsItem">
          <div className="detailsKey">{keyName} :</div>
          {keyName === "lastModifiedDate" || keyName === "createdDate" ? (
            <div className="detailsValue">{timeFormat(details[keyName])}</div>
          ) : (
            <div className="detailsValue">{details[keyName]}</div>
          )}
        </div>
      );
    });
  }

  renderCourselines = (courselines, courseId) => {
    const { activeOuid } = store.getState().permission;
    const courselinesSorted = courselines.sort((a,b)=> a.data.sequenceNumber-b.data.sequenceNumber);
    return courselinesSorted.map((item, index) => (
      <>
        {" "}
        <div
          className={
            item.currentSelection
              ? "CourseModuleTumbnail Active"
              : "CourseModuleTumbnail"
          }
          key={item.data.identifier}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            this.state.courselines.forEach((element) => {
              if (element["currentSelection"] === "true") {
                delete element["currentSelection"];
              }
              if (element.data.identifier === item.data.identifier) {
                element["currentSelection"] = "true";
              }
            });
            this.setState({
              currentVideo: `${item.data.courseVideo}`,
              currentSequenceNumber: `${item.data.sequenceNumber}`,
              currentSummary: `${item.data.summary}`,
              currentTitle: `${item.data.title}`,
              currentIdentifier: `${item.data.identifier}`,
            });
          }}
        >
          <div>
            <img src={item.data.featuredImage} />
          </div>
          <div>
            <div className="CourseModuleTitle">{item.data.title}</div>
            {/* <div>Chapter: {item.data.sequenceNumber}</div> */}
          </div>
        </div>
      </>
    ));
  };

  renderCourselinesForAdmin = (courselines, courseId) => {
    const { activeOuid } = store.getState().permission;
    return courselines.map((item, index) => (
      <>
        {" "}
        <div
          className={
            item.currentSelection
              ? "CourseModuleTumbnail"
              : "CourseModuleTumbnail"
          }
          key={item.data.identifier}
          onClick={() => {
            this.state.courselines.forEach((element) => {
              if (element["currentSelection"] === "true") {
                delete element["currentSelection"];
              }
              if (element.data.identifier === item.data.identifier) {
                element["currentSelection"] = "true";
              }
            });
            this.setState({
              currentVideo: `${item.data.courseVideo}`,
              currentSequenceNumber: `${item.data.sequenceNumber}`,
              currentSummary: `${item.data.summary}`,
              currentTitle: `${item.data.title}`,
            });
          }}
        >
          <div>
            <img src={item.data.featuredImage} />
          </div>
          <div>
            <div>
              <strong>Title : </strong>
              {item.data.title}
            </div>
            <div>
              <strong>SequenceNumber : </strong>
              {item.data.sequenceNumber}
            </div>
            <div>
              <strong>FeaturedImage : </strong>
              {item.data.featuredImage}
            </div>
            <div>
              <strong>CourseVideo : </strong>
              {item.data.courseVideo}
            </div>
          </div>
        </div>
      </>
    ));
  };

  render() {
    const { showGlobalLoader } = store.getState().course;
    const { permissions } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    return (
      <div className="page">
        <div className="container">
          <SidebarWrapper auth={this.props.auth} permissions={permissions} />
          <div className="section">{this.renderCourse()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  course: state.course,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  get_course: (activeOuid, activeOuidChain, id) =>
    dispatch(get_course(activeOuid, activeOuidChain, id)),
  get_courselines: (activeOuid, id) =>
    dispatch(get_courselines(activeOuid, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCourseDetails);
