const dev = {
  api: {
    invokeUrl: "https://2whmaydnig.execute-api.ap-south-1.amazonaws.com/dev",
    coursesUrl: "https://ukg4ilgzx9.execute-api.ap-south-1.amazonaws.com/dev",
    woUrl: "https://5l0qs5v72m.execute-api.ap-south-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_kMIB5TTv5",
    APP_CLIENT_ID: "2ql41ebm00erb21hmcch1lnl95",
  },
  payemntSecretName: "ibc_razorpay",
  razorpayId: "rzp_test_pOy0pZDFzJ8md8",
};

const prod = {
  api: {
    invokeUrl: "https://8pipzip4r4.execute-api.ap-south-1.amazonaws.com/prod",
    coursesUrl: "https://j96mfe515l.execute-api.ap-south-1.amazonaws.com/prod",
    woUrl: "https://tbbs4tod06.execute-api.ap-south-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_SH1IBTzeA",
    APP_CLIENT_ID: "6b82ip7ki5ci3dpsq2bn49cksa",
  },
  payemntSecretName: "",
  razorpayId: "",
};
const prodA = {
  api: {
    invokeUrl: "https://03ln13y8l4.execute-api.ap-south-1.amazonaws.com/prod",
    coursesUrl: "https://8ypugvxsvf.execute-api.ap-south-1.amazonaws.com/prod",
    woUrl: "https://bowh7gnm9d.execute-api.ap-south-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_Nf35p6lDj",
    APP_CLIENT_ID: "733v0mvjcuhgo61o655ig4i4vd",
  },
  payemntSecretName: "",
  razorpayId: "",
};
let config = dev;
if (process.env.REACT_APP_STAGE) {
  switch (process.env.REACT_APP_STAGE.trim()) {
    case "dev":
      config = dev;
      break;
    case "test":
      config = test;
      break;
    case "prod":
      config = prod;
      break;
    case "prodA":
      config = prodA;
      break;
    default:
      config = dev;
      break;
  }
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
