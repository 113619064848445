import React, { Component } from "react";
import { connect } from "react-redux";
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import { setJwt } from "../../actions/action-jwt";
import { setAllPermissions } from "../../actions/action-permissions";
import {
  login_requested,
  login_success,
  login_failed,
} from "../../actions/action-auth";
import configureStore from "../../store/configureStore";
import jwt from "jwt-decode";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "../common/Loader";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";


import { get_profile_details } from "../../actions/action-myProfile";

import {
  setActiveOuid,
  setActiveOuidChain,
} from "../../actions/action-permissions";

import { setActiveOiudConfigs } from "../../actions/action-config";

import { userRoleCheck } from "../common/CommonFunctions";

import LoginImage from "../../components/assets/images/edwinImages/weblogo1.png";

const store = configureStore();

class LogIn extends Component {
  state = {
    username: "",
    password: "",
    redirection: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
  };

  componentDidMount() {
    this.checkRedirection();
  }

  checkRedirection() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirection = urlParams.get("redirect");
    if (redirection !== null) {
      console.log("redirection : ", redirection);
      this.setState({
        redirection: redirection,
      });
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    }

    // AWS Cognito integration here
    try {
      store.dispatch(login_requested());
      const user = await Auth.signIn(this.state.username, this.state.password);
      store.dispatch(login_success());
      console.log("currentSession user", user);
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);
      const { setJwt, get_profile_details } = this.props;
      await setJwt(user);
      await get_profile_details(user.username);
      const idToken = user.signInUserSession.idToken;
      const decodedToken = jwt(idToken.jwtToken);
      if (decodedToken.policies) {
        const policies = decodedToken.policies.split(";");
        let teamPolicies = [];
        if (decodedToken.teamPolicies !== "")
          teamPolicies = decodedToken.teamPolicies.split(";");
        let appPolicies = [];
        if (decodedToken.appPolicies !== "")
          appPolicies = decodedToken.appPolicies.split(";");
        const allPolicy = {
          policies,
          teamPolicies,
          appPolicies,
        };
        const {
          setAllPermissions,
          setActiveOuid,
          setActiveOuidChain,
          setActiveOiudConfigs,
        } = this.props;
        await setAllPermissions(allPolicy);
        const check = await userRoleCheck(policies);
        const { userRoles } = store.getState().permission;
        const { details } = store.getState().myProfile;
        console.log("details myProfile", details);

        await setActiveOuid(userRoles[0].ouid);
        await setActiveOuidChain(userRoles[0].ouid);
        await setActiveOiudConfigs(userRoles[0].ouid);
        if (this.state.redirection) {
          console.log("this.state.redirection is true");

          this.props.history.push(this.state.redirection);
        } else {
          const { activeOuidCoreConfigs } = store.getState().config;

          if (activeOuidCoreConfigs.data) {
            //could be there for rootOrg, but not system org.
            console.log("policies", policies);

            if (policies.includes(`${userRoles[0].ouid}=user`)) {
              const defaultUrl = activeOuidCoreConfigs.data.config.home;
              this.props.history.push(`/${userRoles[0].ouid}/${defaultUrl}`);
            } else {
              this.props.history.push(`/${userRoles[0].ouid}/`);
            }
          } else {
            this.props.history.push(`/${userRoles[0].ouid}/dashboard`);
          }
        }
      } else {
        let myProfile = store.getState().myProfile;
        console.log("myProfile", myProfile);
        this.props.history.push(`/system/user-home`);
      }
    } catch (error) {
      await store.dispatch(login_failed());
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        },
      });
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    const { showGlobalLoader } = store.getState().auth_state;
    return (
      <div className="fullPage">
        <div className="fullPageform ">
          <div className="container--narrow">
            <Link to="/" >
              <img src={LoginImage} alt="" className="loginLogo" />
            </Link>
            <Paper>
              <div className="loginFormWrapper">
                <div className="loginLightMessage">Log in to continue to:</div>
                <div className="loginDarkMessage">Online Learning Platform</div>
                <FormErrors formerrors={this.state.errors} />
                {showGlobalLoader ? <Loader /> : <div></div>}
                <form onSubmit={this.handleSubmit}>
                  <div className="field">
                    <p className="control">
                      <TextField
                        className="input"
                        variant="outlined"
                        label="Email"
                        type="text"
                        id="username"
                        aria-describedby="usernameHelp"
                        placeholder="Enter Email"
                        value={this.state.username}
                        onChange={this.onInputChange}
                        fullWidth
                      />
                    </p>
                  </div>
                  <div className="field">
                    <p className="control">
                      <TextField
                        className="input"
                        variant="outlined"
                        label="Password"
                        type="password"
                        id="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onInputChange}
                        fullWidth
                      />
                    </p>
                  </div>
                  <div className="field">
                    <p className="control loginPageLinks">
                      <a href="/forgotpassword">Forgot password?</a>
                    </p>
                  </div>
                  <div className="field">
                    <p className="control">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                      >
                        Login
                      </Button>
                    </p>
                  </div>
                </form>
                <div className="field">
                  <p className="control loginPageLinks">
                    New User? <a href="/register">Sign up for an account</a>
                  </p>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth_state: state.auth_state,
});

const mapDispatchToProps = (dispatch) => ({
  setJwt: (user) => dispatch(setJwt(user)),
  setAllPermissions: (policies) => dispatch(setAllPermissions(policies)),
  setActiveOuid: (ouid) => dispatch(setActiveOuid(ouid)),
  setActiveOuidChain: (ouid) => dispatch(setActiveOuidChain(ouid)),
  setActiveOiudConfigs: (ouid) => dispatch(setActiveOiudConfigs(ouid)),
  get_profile_details: (username) => dispatch(get_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
