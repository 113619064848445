import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from 'aws-amplify';
import { Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import LoginImage from '../../components/assets/images/edwinImages/weblogo1.png';
import Button from '@material-ui/core/Button';


class ForgotPassword extends Component {
  state = {
    email: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  forgotPasswordHandler = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      await Auth.forgotPassword(this.state.email);
      this.props.history.push('/forgotpasswordverification');
    }catch(error) {
      console.log(error);
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      <div className="fullPageImage">
        <img src={LoginImage} alt="" className='loginLogo'/>
        <Paper className="SignupWelcomePage">
          <h1>Forgot your password?</h1>
          <p>
            Please enter the email address associated with your account and we'll
            email you a password reset link.
          </p>
          <FormErrors formerrors={this.state.errors} />
          <form onSubmit={this.forgotPasswordHandler}>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <TextField
                  variant="outlined"
                  fullWidth
                  type="email"
                  className="input"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
                
              </p>
            </div>
            
            <div className="field">
              <p className="control">
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Submit
              </Button>
                
              </p>
            </div>
          </form>
        </Paper>
      </div>
      
    );
  }
}

export default ForgotPassword;