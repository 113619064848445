import React, { Component } from 'react';
import { connect } from 'react-redux';
import SidebarWrapper from '../layout/SidebarWrapper';
import configureStore from '../../store/configureStore';
import usermanagement from '../assets/images/edwinImages/dashusermanagement.png';
import home from '../assets/images/edwinImages/dashhome.png';
import subscription from '../assets/images/edwinImages/dashmembership.png';
import courses from '../assets/images/edwinImages/dashonlineeducation.png';


const store = configureStore();

class Dashboard extends Component {

  render() {
    const { permission, activeOuid } = store.getState().permission;
    
    return (
      <div className="page">
        <SidebarWrapper
          auth={this.props.auth}
          permission={permission}
        />
        <section className="hero is-primary">
          <div className="dashboardItemWrapper">
            <a href='/'>
              <img src={home} />
              <p>Home</p>
              
            </a>
            <a href={`/${activeOuid}/course-dash`}>
              <img src={courses} />
              <p>Manage Courses</p>
              
            </a>
            <a href={`/${activeOuid}/sub-dash`}>
              <img src={subscription} />
              <p>Manage Subscriptions</p>
              
            </a>
            <a href={`/${activeOuid}/orgusers`}>
              <img src={usermanagement} />
              <p>Users</p>
              
            </a>
          </div>
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);