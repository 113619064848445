import React, { Component } from "react";
import Header from "../layout/Header";
import banner from "../assets/images/bannerimg.png";
import SidebarWrapper from "../layout/SidebarWrapper";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { history } from "../../reducers";
import Footer from "../layout/Footer";
import configureStore from "../../store/configureStore";
import CommonSnackBar from "../common/CommonSnackBar";
import Loader from "../common/Loader";
import { get_courses_public } from "../../actions/action-course";
import { setActiveOuid } from "../../actions/action-permissions";
import CourseCardPublic from "../pages/Post/CourseCardPublic";
import TextField from "@material-ui/core/TextField";
import { showSnackbar } from "../../actions/action-snackbar";
import axios from "axios";


// Images
import homeBanner from "../assets/images/edwinImages/Bannerimage.png";
import vision from "../assets/images/edwinImages/vision.png";
import mission from "../assets/images/edwinImages/mission.png";


const store = configureStore();
class Home extends Component {
  constructor() {
    super();
    this.state = {
      coursesToShow: [],
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
    };
    
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    let activeOuid = "edwin";
    let activeOuidChain = ["system", "edwin"];
    const { setActiveOuid } = this.props;
    await setActiveOuid("edwin");

    this.setState({
      coursesToShow: [],
    });
    const { get_courses_public } = this.props;
    await get_courses_public(activeOuid, activeOuidChain, "", activeOuid);

    this.setState({
      coursesToShow: store.getState().course.courses,
    });
  }

  renderPosts(PostList) {
    console.log("PostList : ", JSON.stringify(PostList));
    return PostList.map((item) => (
      <CourseCardPublic key={item.title} Post={item} />
    ));
  }
  onInputChange = (event) => {
    if (event.target.id) {
      this.setState({ ...this.state, [event.target.id]: event.target.value });
    } else {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
  };

  createOpenWorkorder() {
    const path = window.location.pathname.split("/");
    let ouid = path[1];
    const { woUrl } = store.getState().jwt.api;
    const method = "post";
    const url = `${woUrl}/workorder-public`;
    const axiosHeader = {
      headers: {
        "x-ouid": 'edwin',
      },
    };

    const enqDetails = {
      email: this.state.Email,
      name: this.state.Name,
      phone: this.state.Phone,
      message: this.state.Message,
    };

    const payload = {
      type: "ContactRequestWorkorder",
      description: "Contact form",
      targetOrg: ouid,
      requestId: "",
      deadline: "",
      details: enqDetails,
    };

    console.log("payload:::::::", JSON.stringify(payload));
    console.log("url:::::::", url);

    return axios[method](url, payload, axiosHeader)
      .then(async (response) => {
        console.log(
          "Create WorkOrder response : ",
          JSON.stringify(response.data)
        );

        console.log("response.data.identifier: ", response.data.identifier);

        await store.dispatch(
          showSnackbar("You message had been send Successfully", "success")
        );
      })
      .catch(async (err) => {
        store.dispatch(
          await showSnackbar(
            "Enquire form submission Failed, email support@xyz.com",
            "error"
          )
        );
        store.dispatch(
          showSnackbar(
            "Course Access Request Failed, email support@xyz.com",
            "error"
          )
        );
      });
  }
  

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().course;
    return (
      <div className="Homepage">
        <Header auth={this.props.auth} />
        <section className="hero is-primary homePageBanner">
          <div className="hero-body">
            <div className="container">
              <div className="split reverse">
                <div>
                  <h1>The best way to build tomorrow</h1>
                  <p>
                    In this dynamic world,a single advantage will tip the balance to your benefit.Organizations around the world are also heavily investing in specialized courses aimed at improving technical an academic skills. This initiatives are a welcome change because they enable students and working employees to actively analyze business paradigm and to expand their job opportunities. The courses and trainings provided by Edwin Academy are relevant and help you keep up with the latest technology and regulations of the industry.
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Get started
                  </Button>
                </div>
                <div>
                  <img src={homeBanner} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="homeSection">
          <div className="container">
            <h2 className="section-heading text-center">Our Courses</h2>
              {showGlobalLoader ? (
                <Loader />
              ) : (
                <div className="">
                  <div>
                    <div className="CourseWrapperflex">
                      {this.state.coursesToShow.length !== 0
                        ? this.renderPosts(this.state.coursesToShow)
                        : "No Courses found..."}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </section>
        <section className="homeSection">
          <div className="container">
            <div className="split visionMissionItemWrapper">
                <div className="visionMissionItem">
                  <img src={vision} />
                  <h2>Our Mission</h2>
                  <p>We aspire to become an educational academy that instils ambition, desire, creativity, and all other essential 21st-century skills in learners, helping in widening their minds & succeed in this world filled with challenges. Our mission is to be a learner-friendly institute that provides cost-effective and quality-centric training for all students who enrol in our academy. </p>
                </div>
                <div className="visionMissionItem">
                  <img src={mission} />
                  <h2>Our Vision</h2>
                  <p>We are a group of education providers who strongly believe in the harmony of skill development and the persistent conceptualization of innovations that contributes to the growth of an individual. We aim to build a community of skilled people who are, active, inspiring, and well-prepared to identify opportunities, face challenges, set aspirational targets for themselves, and make practical contributions that shape the future of society.</p>
                </div>
            </div>
          </div>
        </section>
        <section className="homeEnquireFormSection">
          <div className="split">
            <div className='map'>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15611.446013817189!2d75.3666609!3d11.9840837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x20d73d44cd69f732!2sMizone%20%2F%20Malabar%20Innovation%20Entrepreneurship%20Zone!5e0!3m2!1sen!2sin!4v1652347676364!5m2!1sen!2sin"
                width="100%"
                height="500"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              />
            </div>
            <div className="homeEnquireForm">
              <h2>Get in touch</h2>
              <p>Fill your details and submit. One of our executive will get in touch with you soon.</p>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  variant="outlined"
                  label="Name"
                  type="text"
                  id="Name"
                  placeholder="Name"
                  onChange={this.onInputChange}
                  fullWidth
                />
              </div>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  variant="outlined"
                  label="Email"
                  type="text"
                  id="Email"
                  placeholder="Enter Email"
                  onChange={this.onInputChange}
                  fullWidth
                />
              </div>
              
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  variant="outlined"
                  label="Phone"
                  type="text"
                  id="Phone"
                  placeholder="Phone"
                  onChange={this.onInputChange}
                  fullWidth
                />
              </div>
              <div className="form-item-wrapper">
                <TextField
                  className="input"
                  variant="outlined"
                  label="Message"
                  type="text"
                  id="Message"
                  placeholder="Message"
                  onChange={this.onInputChange}
                  fullWidth
                />
              </div>
              <div className="form-item-wrapper">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.createOpenWorkorder()}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <CommonSnackBar />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),

  get_courses_public: (activeOuid, activeOuidChain, category, ouid) =>
    dispatch(get_courses_public(activeOuid, activeOuidChain, category, ouid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
